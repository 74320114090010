import React, { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Loader, StarRating } from "./../../components/Elements";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import {
  businessDetailsMainApi,
  businessDetailsSuccess,
} from "../../store/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../components/Layout";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./CategoryDetails.css";
import { getRndomeNumber } from "../../common/Function/utils";

const CategoryDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);

  const [previousRoute, setPreviousRoute] = useState(null);

  const businessListing = useSelector(
    (state) => state.actionReducer.businessListing
  );
  const Loading = useSelector((state) => state.actionReducer.Loading);
  const [businessDetails, setBusinessDetails] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [businessDetailsID, setBusinessDetailsID] = useState(true);

  const latitude = parseFloat(localStorage.getItem("latitudeValue"));
  const longitude = parseFloat(localStorage.getItem("longitudeValue"));

  useEffect(() => {
    // When the component mounts, check if there's a previous route and set it
    if (location.state && location.state.from) {
      setPreviousRoute(location.state.from);
    }
  }, [location]);

  useEffect(() => {
    const storedData = localStorage.getItem("newBusinessIdData");
    if (storedData) {
      const newBusinessIdData = JSON.parse(storedData);
      dispatch(
        businessDetailsMainApi(navigate, newBusinessIdData, setShowLoader)
      );
      setBusinessDetailsID(newBusinessIdData);
      localStorage.removeItem("newBusinessIdData");
    }
    return () => {
      // Clear the businessListing state when navigating to a different page
      dispatch(businessDetailsSuccess([], "")); // Replace with your actual Redux action to clear the state
    };
  }, []);

  useEffect(() => {
    if (businessListing) {
      setBusinessDetails(businessListing);
    }
  }, [businessListing]);

  console.log(businessDetails.name, "businessListingbusinessListing");

  // to open phone app
  const callPhoneNumber = () => {
    const phoneUrl = `tel:${businessDetails.contactNumber}`;
    // Open the phone app with the number
    window.location.href = phoneUrl;
  };

  const isAndroid = () => /android/i.test(navigator.userAgent);
  const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

  // to open map on app or browser
  const openGoogleMapsApp = () => {
    const locationUrl = businessDetails.location; // This should be the URL from your business details
    let googleMapsUrl;
    // Handle platform-specific behavior
    if (isAndroid()) {
      // Android: Use the default Google Maps link
      googleMapsUrl = locationUrl; // https://maps.google.com format will work fine on Android
    } else if (isIOS()) {
      // iOS: Use the maps:// URL scheme
      googleMapsUrl = locationUrl.replace("https://", "maps://");
    } else {
      googleMapsUrl = locationUrl;
    }
    // Open the link in the appropriate app or browser
    window.open(googleMapsUrl, "_blank");
  };

  const handleGoBack = () => {
    if (previousRoute) {
      navigate(previousRoute);
    } else {
      navigate(-1); // Go back to the previous route in the history stack
    }
  };

  const clickHomeHandler = () => {
    navigate("/BlankSpace/");
  };

  const onClickWebsiteHandler = () => {
    if (businessDetails && businessDetails.website) {
      window.open(businessDetails.website, "_blank");
    } else {
      console.error("Website URL is not available");
    }
  };

  return (
    <Fragment>
      {Object.keys(businessDetails).length > 0 && (
        <Container className="backgroundBody" fluid>
          {businessDetails.listOfBase64Images &&
            Object.keys(businessDetails.listOfBase64Images).length > 0 && (
              <div className="swiper-category-slider">
                <Row>
                  <Col lg={12} md={12} sm={12} className="p-0">
                    <Swiper
                      spaceBetween={30}
                      centeredSlides={true}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Autoplay, Pagination]}
                      className="carousel-swiper"
                    >
                      {Object.values(businessDetails.listOfBase64Images).map(
                        (base64Image, index) => (
                          <SwiperSlide key={getRndomeNumber()}>
                            <img
                              src={`data:image/jpeg;base64,${base64Image}`}
                              alt={`Slide ${index}`}
                              className="slide-Image"
                            />
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </Col>
                </Row>
              </div>
            )}
          <Row
            className={`${
              Object.keys(businessDetails.listOfBase64Images).length > 0
                ? "mb-4 margin-top-after-image"
                : "mb-4"
            }`}
          >
            <Col lg={6} md={6} sm={6} xs={6} className="mt-2">
              <span className="title-emirates-heading">
                {businessDetails && businessDetails.name}
              </span>
              <Row className="mb-3">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <span className="show-inline-display">
                    <StarRating
                      className="star-font-size"
                      rating={businessDetails.rating}
                    />
                    <span className="Reviews-after-star">
                      ({businessDetails.reviewsCount} Reviews)
                    </span>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} sm={6} xs={6}>
                  <Button
                    text={businessDetails.categoryName}
                    className="Tour-button"
                  />
                </Col>
              </Row>
            </Col>

            <Col
              lg={6}
              md={6}
              sm={6}
              xs={6}
              className="d-flex justify-content-end align-items-end"
            >
              <Button
                text="Go Back"
                className="go-back-to-search"
                onClick={handleGoBack}
              />
              {/* {businessDetails.packageStarts !== "" && (
                <>
                  <span className="verticalline"></span>
                  <div className="line-height">
                    <div className="package-start-heading">PACKAGES STARTS</div>
                    <div className="package-start-heading">
                      From{" "}
                      <span className="five-dollar-title">
                        ${businessDetails.packageStarts}
                      </span>
                    </div>
                  </div>
                </>
              )} */}
            </Col>
            {/* <Col lg={2} md={2} sm={2} xs={2}>
              <Button
                text="Go Back"
                className="Go-Back-Button"
                onClick={handleGoBack}
              />
            </Col> */}
          </Row>

          <Row>
            <Col lg={12} md={12} sm={12}>
              <p className="para-text">{businessDetails.aboutUs}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12} className="Grourp-btn-col">
              <Button
                icon={<i className="icon-home call-etc-icons-sizes"></i>}
                onClick={clickHomeHandler}
                className="buttons-call-etc"
              />
              {businessDetails && businessDetails.contactNumber ? (
                <Button
                  icon={<i className="icon-call call-etc-icons-sizes"></i>}
                  className="buttons-call-etc"
                  onClick={callPhoneNumber}
                />
              ) : (
                <Button
                  icon={<i className="icon-call call-etc-icons-sizes"></i>}
                  className="buttons-disable-etc"
                />
              )}

              {businessDetails && businessDetails.location ? (
                <Button
                  icon={<i className="icon-location call-etc-icons-sizes"></i>}
                  className="buttons-call-etc"
                  onClick={openGoogleMapsApp}
                />
              ) : (
                <Button
                  icon={<i className="icon-location call-etc-icons-sizes"></i>}
                  className="buttons-disable-etc"
                />
              )}
              {console.log(businessDetails.website, "businessDetailsWebsite")}

              {businessDetails && businessDetails.website ? (
                <Button
                  icon={<i className="icon-web call-etc-icons-sizes"></i>}
                  onClick={onClickWebsiteHandler}
                  // onClick={() =>
                  //   window.open(businessDetails.website, "_system")
                  // }
                  className="buttons-call-etc"
                />
              ) : (
                <Button
                  icon={<i className="icon-web call-etc-icons-sizes"></i>}
                  className="buttons-disable-etc"
                />
              )}
            </Col>
          </Row>

          {/* section for what we offer */}

          <Row className="mt-4">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-start"
            >
              <span className="what-we-offer-heading">WHAT WE OFFER</span>
            </Col>
          </Row>

          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-start"
            >
              <p className="para-text">{businessDetails.offering}</p>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-start"
            >
              <span className="what-we-offer-heading">
                Here's what are customers have to say
              </span>
            </Col>
          </Row>

          <Row>
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-start"
            >
              <div className="para-text">{businessDetails.reviews}</div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg={12} md={12} sm={12}>
              <Footer operationHours={businessDetails.operationHours} />
            </Col>
          </Row>
        </Container>
      )}
      {showLoader ? <Loader /> : null}
    </Fragment>
  );
};

export default CategoryDetails;
